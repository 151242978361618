// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* userPhotoCard.css */
.userphoto {
    /* display: flex; */
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 3rem;
  }
  
  .userPhoto2 {
    /* width: calc(50% - 15px); */
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .userPhoto2:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .userhead {
    margin-bottom: 10px;
    text-align: center;
  }
  
  .logo-img1 {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;
  }
  
  .userPhoto7 {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 6px;
  }
  
  .userPhoto8 {
    font-size: 14px;
    color: #555;
    margin-bottom: 4px;
  }
  
  .userPhoto9 {
    font-size: 14px;
    color: #777;
  }
  `, "",{"version":3,"sources":["webpack://./src/component/usercard.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,mBAAmB;IACnB,eAAe;IACf,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA;IACE,6BAA6B;IAC7B,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,sBAAsB;IACtB,gCAAgC;IAChC,eAAe;EACjB;;EAEA;IACE,yCAAyC;EAC3C;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,WAAW;EACb","sourcesContent":["/* userPhotoCard.css */\n.userphoto {\n    /* display: flex; */\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin-top: 3rem;\n  }\n  \n  .userPhoto2 {\n    /* width: calc(50% - 15px); */\n    margin-bottom: 20px;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n    background-color: #fff;\n    transition: box-shadow 0.3s ease;\n    cursor: pointer;\n  }\n  \n  .userPhoto2:hover {\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);\n  }\n  \n  .userhead {\n    margin-bottom: 10px;\n    text-align: center;\n  }\n  \n  .logo-img1 {\n    width: 40px;\n    height: 40px;\n    margin-bottom: 8px;\n  }\n  \n  .userPhoto7 {\n    font-size: 18px;\n    font-weight: bold;\n    color: #333;\n    margin-bottom: 6px;\n  }\n  \n  .userPhoto8 {\n    font-size: 14px;\n    color: #555;\n    margin-bottom: 4px;\n  }\n  \n  .userPhoto9 {\n    font-size: 14px;\n    color: #777;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
