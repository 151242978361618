import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';
const common = require('../utils/Common');

const EmailTemplates = () => {
    let navigate = useNavigate();
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [modal, setModal] = useState(false);
    const [editData, setEditData] = useState(null);

    const toggleAddModal = (data) => {
        setModal(!modal);
        setEditData(data);
    }

    const addEditDeleteTemplate = async (id, action) => {
        const templateBodyContent = editorInstance ? editorInstance.getData() : null;
        var parameters = {
          'action': action,
          'id': id,
          'template_name': $('#template_name').val(),
          'template_body': templateBodyContent
        };
        let response = await common.apiRequest('post', '/admin/crud-email-templates', parameters);
        if (response.status == 200) {
          if (response.data.code == "success") {
            alert(response.data.message);
            if (action == "update" || action == "create") {
                toggleAddModal(null);
            }
            getEmailTemplates();
            navigate('/email-templates');
          } else {
            alert(response.data.error, 'error');
          }
        }
    }

    const getEmailTemplates = async () => {
        var parameters = {
          'action': 'read'
        }
        let response = await common.apiRequest('post', '/admin/crud-email-templates', parameters);
        if (response.status == 200) {
          if (response.data.code == "success") {
            setEmailTemplates(response.data.data);
          } else {
            alert(response.data.error, 'error');
          }
        }
    }

    useEffect(() => {
        checkAccess();
    },[]);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
          if (!localStorage.getItem('token')) {
            navigate('/login');
          }
          getEmailTemplates();
        }
    }

    let editorInstance;

    return (
        <>
            <h1>All Email Templates</h1><br/>
            <Button color="primary" style={{float: 'right', marginRight: '50px', marginBottom: '20px'}} onClick={() => toggleAddModal(null)}>Add New Template</Button>
            
            <Modal isOpen={modal} toggle={() => toggleAddModal(null)}>
                <ModalHeader toggle={() => toggleAddModal(null)}>{editData ? 'Edit' : 'Add'} New Template</ModalHeader>
                <ModalBody>
                    <input type='text' id="template_name" placeholder='Enter Template Name' defaultValue={editData ? editData.template_name : null}/>
                    <CKEditor editor={ClassicEditor} id="template_body" data={editData ? editData.template_body : ''} onReady={editor => {editorInstance = editor;}} />
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => addEditDeleteTemplate(editData ? editData.id : 0, editData ? 'update' : 'create')}>
                        Save
                    </Button>{' '}
                    <Button color="danger" onClick={() => toggleAddModal(null)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

            <Table striped>
                <thead>
                    <tr className='table-primary'>
                        <th>S.No</th>
                        <th>Template Name</th>
                        <th>Template Body</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {emailTemplates.length === 0 ? (
                        <tr>
                            <td align="center" colSpan="7">
                                <center>No Data Available</center>
                            </td>
                        </tr>
                    ) : (
                        emailTemplates.map((template, index) => (
                            <tr key={index}>
                                <td>{++index}</td>
                                <td>{template.template_name}</td>
                                <td>{template.template_body}</td>
                                <td>{template.created_at}</td>
                                <td>{template.updated_at}</td>
                                <td>
                                    <Button className="btn btn-warning" onClick={() => toggleAddModal(template)}>Edit Template</Button>
                                </td>
                                <td>
                                    <Button className="btn btn-danger" onClick={() => addEditDeleteTemplate(template.id, 'delete')}>Delete Template</Button>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </Table>
        </>
    )
}

export default EmailTemplates;