import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import './clienttable.css'
import { Row } from 'react-bootstrap';
const common = require('../utils/Common');

const ClientTable = () => {
  const navigate = useNavigate();

  useEffect(() => {
    checkAccess();
  },[]);

  const checkAccess = async () => {
    let haveAccess = await common.checkAccessRights(window.location.pathname);
    if (!haveAccess) {
      alert("Access Denied");
      navigate("/");
    }
  }

  const renderTableRows = () => {
    return table.map((rowData, index) => (
      <tr key={index}>
        <td>{rowData.Code}</td>
        <td>{rowData.Company}</td>
        <td>{rowData.Price}</td>
        <td>{rowData.Profit}</td>
        <td>{rowData.Balane}</td>
      </tr>
    ));
  };

  const table=[
    { Code: "", Company: '', Price: "", Profit: "",  Balane: ''},
    { Code: "", Company: '', Price: "", Profit: "" , Balane: ''},
    { Code: "", Company: '', Price: "", Profit: "" , Balane: ''},
    { Code: "", Company: '', Price: "", Profit: "" , Balane: ''},
    { Code: "", Company: '', Price: "", Profit: "" , Balane: ''},
    { Code: "", Company: '', Price: "", Profit: "" , Balane: ''},
    { Code: "", Company: '', Price: "", Profit: "" , Balane: ''},

  ]
  return (
    <>
    <div className='tablebody'>
    <div class="tbl-header">
    <table cellpadding="0" cellspacing="0" border="0">
      <thead>
        <tr>
          <th>Code</th>
          <th>Company</th>
          <th>Price</th>
          <th>Profit</th>
          <th>Balane</th>
        </tr>
      </thead>
    </table>
  </div>
  <div class="tbl-content">
    <table cellpadding="0" cellspacing="0" border="0">
      <tbody>
         {renderTableRows()}
      </tbody>
    </table>
  </div>
    </div>
    </>
  )
}

export default ClientTable