import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
const common = require('../utils/Common');

const AdminPortals = () => {
    let navigate = useNavigate();
    const [portalsData, setPortalsData] = useState([]);
    const [editPortalsData, setEditPortalsData] = useState({});
    const [modal, setModal] = useState(false);

    const toggle = (item) => {
        setModal(!modal);
        if (item != "") {
            setEditPortalsData({
                'portal_name': item.portal_name,
                'portal_url': item.portal_url,
                'id': item.id
            })
        }
    }

    const crudAdminPortals = async (action, id) => {
        let data = {
			"action": action,
            "id": id
		}
        data.portal_name = $('#portal_name').val();
        data.portal_url = $('#portal_url').val();
		let response = await common.apiRequest('post', '/admin/crud-admin-portals', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                if (action == "create" || action == "delete" || action == "update") {
                    alert(response.data.message);
                    crudAdminPortals('read', 0);
                    if (action == "create" || action == "update") {
                        toggle("");
                    }
                } else if (action == "read") {
                    setPortalsData(response.data.data)
                }
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

	useEffect(() => {
        checkAccess();
	}, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname, 1);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            crudAdminPortals('read', 0);
        }
      }

    return (
        <>
        <h1>All Portals</h1><br/>
        <button className='btn btn-primary' style={{float: 'right', marginRight: '50px', marginBottom: '20px'}} onClick={() => toggle("")}>Add New Portal</button>
        <Modal isOpen={modal} toggle={() => toggle("")}>
            <ModalHeader>{editPortalsData.portal_name ? 'Edit' : 'Add' } New Portal</ModalHeader>
            <ModalBody>
                <input type='text' id="portal_name" placeholder='Enter Portal Name' defaultValue={editPortalsData.portal_name ? editPortalsData. portal_name : ''}/>
                <input type='text' id= "portal_url" placeholder='Enter Portal URL' defaultValue={editPortalsData.portal_url ? editPortalsData. portal_url : ''}/>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={() => crudAdminPortals(editPortalsData.portal_name ? 'update' : 'create', editPortalsData.portal_name ? editPortalsData.id : 0)}>
                    Save
                </Button>{' '}
                <Button color="danger" onClick={() => toggle("")}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
        <Table striped>
            <thead>
                <tr className='table-primary'>
                    <th>Portal Name</th>
                    <th>Portal URL</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    portalsData.length == 0
                    ?
                        <tr>
                            <td align="center" colspan="7">
                                <center>No Portals Available</center>
                            </td>
                        </tr>
                    :
                    portalsData.map((portalData) => {
                        return (
                            <>
                                <tr>
                                    <td>{portalData.portal_name}</td>
                                    <td>{portalData.portal_url}</td>
                                    <td>
                                        {/* <button className='btn btn-danger btn-sm' style={{marginRight: '20px'}}>Mark Inactive</button> */}
                                        <button className='btn btn-warning btn-sm' style={{marginRight: '20px'}} onClick={() => toggle(portalData)}>Update</button>
                                        <button className='btn btn-danger btn-sm' onClick={() => crudAdminPortals('delete', portalData.id)}>Delete</button>
                                    </td>
                                </tr>
                            </>
                        );
                    })
                }
            </tbody>
        </Table>
        </>
    )
}

export default AdminPortals;