import React from 'react'
import "./footer.css";
const Footer = () => {
  return (
    <>
        <div className='footer'>
            <div className='foterdata'>
                    © Copyright by Ind-Gov -2022-2023
            </div>  
        </div>
    </>
  )
}

export default Footer