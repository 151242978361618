// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* dataCharts.css */
.charts {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  /* position: relative; */
}

.chart2 {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
}

/* Styles for the chart container */
.charts1 {
  max-width: 960px;
}

/* Styles to center the chart horizontally */
.chart2 {
  margin: 0 auto;
}

/* Styles for X and Y axes labels */
.recharts-cartesian-axis-tick-value {
  font-size: 14px;
  fill: #555;
}

/* Styles for the tooltip */
.recharts-tooltip-wrapper {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333;
}

/* Styles for the tooltip label */
.recharts-tooltip-label {
  font-weight: bold;
}

/* Styles for the tooltip value */
.recharts-tooltip-item {
  margin-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/component/datachart.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,sBAAsB;EACtB,wCAAwC;EACxC,kBAAkB;EAClB,aAAa;AACf;;AAEA,mCAAmC;AACnC;EACE,gBAAgB;AAClB;;AAEA,4CAA4C;AAC5C;EACE,cAAc;AAChB;;AAEA,mCAAmC;AACnC;EACE,eAAe;EACf,UAAU;AACZ;;AAEA,2BAA2B;AAC3B;EACE,0CAA0C;EAC1C,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,wCAAwC;EACxC,eAAe;EACf,WAAW;AACb;;AAEA,iCAAiC;AACjC;EACE,iBAAiB;AACnB;;AAEA,iCAAiC;AACjC;EACE,eAAe;AACjB","sourcesContent":["/* dataCharts.css */\n.charts {\n  display: flex;\n  justify-content: center;\n  margin-top: 60px;\n  /* position: relative; */\n}\n\n.chart2 {\n  background-color: #fff;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n  padding: 20px;\n}\n\n/* Styles for the chart container */\n.charts1 {\n  max-width: 960px;\n}\n\n/* Styles to center the chart horizontally */\n.chart2 {\n  margin: 0 auto;\n}\n\n/* Styles for X and Y axes labels */\n.recharts-cartesian-axis-tick-value {\n  font-size: 14px;\n  fill: #555;\n}\n\n/* Styles for the tooltip */\n.recharts-tooltip-wrapper {\n  background-color: rgba(255, 255, 255, 0.9);\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  padding: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  font-size: 14px;\n  color: #333;\n}\n\n/* Styles for the tooltip label */\n.recharts-tooltip-label {\n  font-weight: bold;\n}\n\n/* Styles for the tooltip value */\n.recharts-tooltip-item {\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
