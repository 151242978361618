// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tablessd{
    margin-top: 40px !important
}
.serviceCard{
    margin-right: 80px;
    margin-top: 10%;
}

.table-responsive thead tr th{
    color: black !important;
}

.table-body tr td{
    color: black !important;
}

.srrrtd{
    color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/component/services.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".tablessd{\n    margin-top: 40px !important\n}\n.serviceCard{\n    margin-right: 80px;\n    margin-top: 10%;\n}\n\n.table-responsive thead tr th{\n    color: black !important;\n}\n\n.table-body tr td{\n    color: black !important;\n}\n\n.srrrtd{\n    color: black !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
