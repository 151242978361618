import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const SellerserviceApprove = () => {
    let navigate = useNavigate();
    const [sellersServiceData, setSellersServiceData] = useState([]);
    const [normalServiceModal, setNormalServiceModal] = useState(false);
    const [logisticsServiceModal, setLogisticsServiceModal] = useState(false);
    const [selectedServiceData, setSelectedServiceData] = useState(null);

    useEffect(() => {
        checkAccess();
    }, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
            alert("Access Denied");
            navigate("/");
        } else {
            getServices();
        }
    };

    const getServices = async () => {
        const data = {
            action: "read",
            source: "admin",
            user_type: "seller"
        };
        let response = await common.apiRequest('post', '/admin/get-sellers-service-data', data);
        if (response.status === 200) {
            if (response.data.code === 'success') {
                setSellersServiceData(response.data.data);
            } else {
                alert(response.data.error);
            }
        } else {
            alert(response.data.error);
        }
    };

    // const approveProduct = async (product_id) => {
    //     let data = {
    //         'id': product_id,
    //         "active": 1,
    //         "action": "update"
    //     };
    //     let response = await common.apiRequest('post', '/seller/crud-seller-services', data);
    //     if (response.data.code === 'success') {
    //         alert(response.data.message);
    //         // window.location.reload(true);
    //         window.location.href = "/seller-service-approve";
    //     } else {
    //         alert(response.data.error);
    //     }
    // };
    const approveProduct = async (product_id) => {
        let data = {
            'id': product_id,
            "active": 1,
            "action": "update"
        };
        let response = await common.apiRequest('post', '/seller/crud-seller-services', data);
        if (response.data.code === 'success') {
            // Update the local state instead of reloading the page
            setSellersServiceData(prevData => 
                prevData.map(seller => ({
                    ...seller,
                    seller_services: seller.seller_services.map(service => 
                        service.id === product_id ? { ...service, active: 1 } : service
                    )
                }))
            );
            setSelectedServiceData(prevData => 
                prevData.map(service => 
                    service.id === product_id ? { ...service, active: 1 } : service
                )
            );
            alert(response.data.message);
        } else {
            alert(response.data.error);
        }
    };
    const movePage = (path, data) => {
        navigate(path, { state: data });
    };

    const toggleNormalServiceModal = (serviceData) => {
        setSelectedServiceData(serviceData);
        setNormalServiceModal(!normalServiceModal);
    };

    const toggleLogisticsServiceModal = (serviceData) => {
        setSelectedServiceData(serviceData);
        setLogisticsServiceModal(!logisticsServiceModal);
    };

    const serviceColumnDefs = useMemo(() => [
        { headerName: "Service Name", field: "service_name" },
        { headerName: "Parent Category", field: "parent_category.category_name" },
        { headerName: "Sub Category", field: "sub_category.category_name" },
        { headerName: "Service Type", field: "service_type" },
        {
            headerName: "Service Image", field: "service_image",
            cellRenderer: (params) => (
                params.value ? <img src={envConfig.api_url + params.value} style={{ height: '100px', width: '100px' }} alt="Service" /> : ''
            )
        },
        { headerName: "Service Description", field: "service_description" },
        { headerName: "Service Price", field: "price" },
        { headerName: "Service SKU", field: "service_sku" },
        { headerName: "Service By", field: "service_by" },
        { headerName: "GST Rate", field: "gst_rate" },
        { headerName: "Taxable Value", field: "taxable_value" },
        { headerName: "Supply From State", field: "supply_from_state" },
        { headerName: "Supply From GST No", field: "supply_from_gst_no" },
        { headerName: "Logistics Delivery Type", field: "logistics_delivery_type" },
        { headerName: "Range In Grams From", field: "range_in_grams_from" },
        { headerName: "Range In Grams To", field: "range_in_grams_to" },
        { headerName: "Zone A", field: "zone_a" },
        { headerName: "Zone B", field: "zone_b" },
        { headerName: "Zone C", field: "zone_c" },
        { headerName: "Zone D", field: "zone_d" },
        { headerName: "Zone E", field: "zone_e" },
        { headerName: "Zone F", field: "zone_f" },
        { headerName: "Minimum Delivery Time", field: "min_delivery_time" },
        { headerName: "Maximum Delivery Time", field: "max_delivery_time" },
        { headerName: "Additional Grams", field: "additional_grams" },
        { headerName: "Additional Price Add In Every Zone", field: "additional_price_add_in_every_zone" },
        {
            headerName: "Actions",
            cellRenderer: (params) => {
                return (
                    params.data.active == 1 ? "Approved" : (
                        <Button color="success" size="sm" onClick={() => approveProduct(params.data.id)}>
                            Approve Service
                        </Button>
                    )
                );
            }
        }
        
    ], []);

    const sellerColumnDefs = useMemo(() => [
        { headerName: "Reg ID", field: "reg_id" },
        { headerName: "PAN No", field: "pan_no" },
        { headerName: "First Name", field: "first_name" },
        { headerName: "Last Name", field: "last_name" },
        { headerName: "Email", field: "email" },
        { headerName: "Mobile Phone", field: "mobile_phone" },
        {
            headerName: "Normal service", field: "normal_service", cellRenderer: (params) => (
                <Button color="primary" size="sm" onClick={() => toggleNormalServiceModal(params.data.seller_services.filter(service => service.service_type === 'normal'))}>
                    Normal service
                </Button>
            )
        },
        {
            headerName: "Logistics service", field: "logistics_service", cellRenderer: (params) => (
                <Button color="primary" size="sm" onClick={() => toggleLogisticsServiceModal(params.data.seller_services.filter(service => service.service_type === 'logistics'))}>
                    Logistics service
                </Button>
            )
        },
    ], []);

    const defaultColDef = useMemo(() => ({
        filter: 'agTextColumnFilter',
        floatingFilter: true,
    }), []);
    const modalStyle = {
        maxWidth: '90vw',
        width: '90vw',
        maxHeight: '90vh',
        height: '90vh',
    };

    const modalBodyStyle = {
        height: 'calc(90vh - 56px)',
        padding: '1rem',
        overflow: 'hidden', 
    };

    const gridContainerStyle = {
        height: '100%',
        width: '100%',
        overflow: 'auto', 
    };

    const gridStyle = {
        height: '100%',
        minWidth: '100%',
    };
    return (
        <>
            <h1>All Services</h1>
            <br />
            <div className="ag-theme-quartz" style={{ height: 500 }}>
                <AgGridReact
                    rowData={sellersServiceData}
                    columnDefs={sellerColumnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 25, 50]}
                />
            </div>

            <Modal isOpen={normalServiceModal} toggle={() => toggleNormalServiceModal(null)} size="xl" style={modalStyle}>
                <ModalHeader toggle={() => toggleNormalServiceModal(null)}>Normal Service</ModalHeader>
                <ModalBody style={modalBodyStyle}>
                    <div style={gridContainerStyle}>
                        <div className="ag-theme-quartz" style={gridStyle}>
                            <AgGridReact
                                rowData={selectedServiceData}
                                columnDefs={serviceColumnDefs}
                                defaultColDef={defaultColDef}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                pagination={true}
                                paginationPageSize={10}
                                paginationPageSizeSelector={[10, 25, 50]}
                            />
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={logisticsServiceModal} toggle={() => toggleLogisticsServiceModal(null)} size="xl" style={modalStyle}>
                <ModalHeader toggle={() => toggleLogisticsServiceModal(null)}>Logistics Service</ModalHeader>
                <ModalBody style={modalBodyStyle}>
                    <div style={gridContainerStyle}>
                        <div className="ag-theme-quartz" style={gridStyle}>
                            <AgGridReact
                                rowData={selectedServiceData}
                                columnDefs={serviceColumnDefs}
                                defaultColDef={defaultColDef}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                pagination={true}
                                paginationPageSize={10}
                                paginationPageSizeSelector={[10, 25, 50]}
                            />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default SellerserviceApprove;
