import React from 'react'
import './usercard.css'
const UserPhotoCard = () => {
  return (
    <>
        <div className='userphoto'>
            <div className='userPhoto1'>
                    <div className='userhead'>
                        <h5>User</h5>
                    </div>
                    <div className='userPhoto2'>
                        <div className='userPhoto3 row'>
                            <div className='col-8 userPhoto4'>
                                <div className='userPhoto5'>
                                 <img className='logo-img1' src="image/user-1.png"/>
                                </div>
                                <div className='userPhoto6'>
                                    <h5 className='userPhoto7'>
                                        
                                    </h5>
                                    <div className='userPhoto8'>
                                        Saller
                                    </div>
                                    <div className='userPhoto9'>
                                    Sign in - 09:30
                                    </div>
                                </div>
                            </div>   
                            <div className='col-4'>
                                <div className='userPhoto10'>
                                    Online
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='userPhoto2'>
                        <div className='userPhoto3 row'>
                            <div className='col-8 userPhoto4'>
                                <div className='userPhoto5'>
                                 <img className='logo-img1' src="image/user-1.png"/>
                                </div>
                                <div className='userPhoto6'>
                                    <h5 className='userPhoto7'>
                                        
                                    </h5>
                                    <div className='userPhoto8'>
                                        Saller
                                    </div>
                                    <div className='userPhoto9'>
                                    Sign in - 09:30
                                    </div>
                                </div>
                            </div>   
                            <div className='col-4'>
                                <div className='userPhoto10'>
                                    Online
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='userPhoto2'>
                        <div className='userPhoto3 row'>
                            <div className='col-8 userPhoto4'>
                                <div className='userPhoto5'>
                                 <img className='logo-img1' src="image/user-1.png"/>
                                </div>
                                <div className='userPhoto6'>
                                    <h5 className='userPhoto7'>
                                        
                                    </h5>
                                    <div className='userPhoto8'>
                                        Saller
                                    </div>
                                    <div className='userPhoto9'>
                                    Sign in - 09:30
                                    </div>
                                </div>
                            </div>   
                            <div className='col-4'>
                                <div className='userPhoto10'>
                                    Online
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='userPhoto2'>
                        <div className='userPhoto3 row'>
                            <div className='col-8 userPhoto4'>
                                <div className='userPhoto5'>
                                 <img className='logo-img1' src="image/user-1.png"/>
                                </div>
                                <div className='userPhoto6'>
                                    <h5 className='userPhoto7'>
                                        
                                    </h5>
                                    <div className='userPhoto8'>
                                        Saller
                                    </div>
                                    <div className='userPhoto9'>
                                    Sign in - 09:30
                                    </div>
                                </div>
                            </div>   
                            <div className='col-4'>
                                <div className='userPhoto10'>
                                    Online
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='userPhoto2'>
                        <div className='userPhoto3 row'>
                            <div className='col-8 userPhoto4'>
                                <div className='userPhoto5'>
                                 <img className='logo-img1' src="image/user-1.png"/>
                                </div>
                                <div className='userPhoto6'>
                                    <h5 className='userPhoto7'>
                                        
                                    </h5>
                                    <div className='userPhoto8'>
                                        Saller
                                    </div>
                                    <div className='userPhoto9'>
                                    Sign in - 09:30
                                    </div>
                                </div>
                            </div>   
                            <div className='col-4'>
                                <div className='userPhoto10'>
                                    Online
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='userPhoto2'>
                        <div className='userPhoto3 row'>
                            <div className='col-8 userPhoto4'>
                                <div className='userPhoto5'>
                                 <img className='logo-img1' src="image/user-1.png"/>
                                </div>
                                <div className='userPhoto6'>
                                    <h5 className='userPhoto7'>
                                        
                                    </h5>
                                    <div className='userPhoto8'>
                                        Saller
                                    </div>
                                    <div className='userPhoto9'>
                                    Sign in - 09:30
                                    </div>
                                </div>
                            </div>   
                            <div className='col-4'>
                                <div className='userPhoto10'>
                                    Online
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </>
  )
}

export default UserPhotoCard