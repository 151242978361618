import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
const common = require('../utils/Common');

const AdminUsers = () => {
    let navigate = useNavigate();
    const [adminUsers, setadminUsers] = useState([]);
    const [editAdminUsers, setEditAdminUsers] = useState([]);
    const [userTypes, setUserTypes] = useState([]);
    const [modal, setModal] = useState(false);

    const toggle = (item) => {
        setModal(!modal);
        setEditAdminUsers(item);
    }

    const crudAdminUsers = async (action, id) => {
        let data = {
			"action": action,
            "id": id
		}
        if (action == 'create' || action == 'update') {
            data.first_name = $('#first_name').val();
            data.last_name = $('#last_name').val();
            data.email = $('#email').val();
            data.password = $('#password').val();
            data.user_type_id = $('#user_type').val();
        }
		let response = await common.apiRequest('post', '/admin/crud-admin-users', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                if (action == "create" || action == "delete" || action == "update") {
                    alert(response.data.message);
                    crudAdminUsers('read', 0);
                    if (action == "create" || action == "update") {
                        toggle("");
                    }
                } else if (action == "read") {
                    setadminUsers(response.data.data)
                }
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

    const getUserTypes = async () => {
        let data = {
			"action": "read"
		}
		let response = await common.apiRequest('post', '/admin/crud-admin-user-types', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                setUserTypes(response.data.data)
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

	useEffect(() => {
        checkAccess();
	}, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname, 1);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            crudAdminUsers('read', 0);
            getUserTypes();
        }
      }

    return (
        <>
        <h1>All Admin Users</h1><br/>
        <button className='btn btn-primary' style={{float: 'right', marginRight: '50px', marginBottom: '20px'}} onClick={() => toggle("")}>Add New User</button>
        <Modal isOpen={modal} toggle={() => toggle("")}>
            <ModalHeader>{editAdminUsers ? "Update" : "Add New"} User</ModalHeader>
            <ModalBody>
                <input type='text' id="first_name" placeholder='Enter First Name' defaultValue={editAdminUsers && editAdminUsers.first_name ? editAdminUsers.first_name : ''}/>
                <input type='text' id="last_name" placeholder='Enter Last Name' defaultValue={editAdminUsers && editAdminUsers.last_name ? editAdminUsers.last_name : ''}/>
                <input type='email' id="email" placeholder='Enter Email' defaultValue={editAdminUsers && editAdminUsers.email ? editAdminUsers.email : ''}/>
                <input type='password' id="password" placeholder='Enter Password' defaultValue={editAdminUsers && editAdminUsers.password ? editAdminUsers.password : ''}/>
                <br/>
                <select id="user_type">
                    <option value="">Select User Type</option>
                    {
                        userTypes.map((userType) => {
                            return (
                                <>
                                    <option value={userType.id} selected={editAdminUsers && editAdminUsers.user_type_id == userType.id ? true : false}>{userType.user_type}</option>
                                </>
                            )
                        })
                    }
                </select>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={() => crudAdminUsers(editAdminUsers ? 'update' : 'create', editAdminUsers && editAdminUsers.id ? editAdminUsers.id : 0)}>
                    Save
                </Button>{' '}
                <Button color="danger" onClick={() => toggle("")}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
        <Table striped>
            <thead>
                <tr className='table-primary'>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email Name</th>
                    <th>User Type</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    adminUsers.length == 0
                    ?
                        <tr>
                            <td align="center" colspan="7">
                                <center>No Data Available</center>
                            </td>
                        </tr>
                    :
                    adminUsers.map((adminUser) => {
                        return (
                            <>
                                <tr>
                                    <td>{adminUser.first_name}</td>
                                    <td>{adminUser.last_name}</td>
                                    <td>{adminUser.email}</td>
                                    <td>{adminUser.admin_user_type.user_type}</td>
                                    <td>
                                        {/* <button className='btn btn-danger btn-sm' style={{marginRight: '20px'}}>Mark Inactive</button> */}
                                        <button className='btn btn-warning btn-sm' style={{marginRight: '20px'}} onClick={() => toggle(adminUser)}>Update</button>
                                        <button className='btn btn-danger btn-sm' style={{marginRight: '20px'}} onClick={() => crudAdminUsers('delete', adminUser.id)}>Delete</button>
                                    </td>
                                </tr>
                            </>
                        );
                    })
                }
            </tbody>
        </Table>
        </>
    )
}

export default AdminUsers;