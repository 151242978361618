import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const SellersProducts = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [allProducts, setAllProducts] = useState([]);
    const [subProducts, setSubProducts] = useState([]);
    const [productData, setProductData] = useState([]);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

	useEffect(() => {
        if (!location.state) {
            navigate('/sellers');
            return;
        }
        checkAccess();
	}, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            if (!location.state) {
                navigate('/');
            }
            // setAllProducts(location.state);
            getProducts()
        }
    }

    const showSubProducts = (subProducts) => {
        setSubProducts(subProducts);
        toggle();
    }

    const approveProduct = async (product_id) => {
        let data = {
            'id': product_id,
        }
        let response = await common.apiRequest('post', '/admin/approve-product', data);
        if (response.data.code == 'success') {
            alert(response.data.message);
            window.location.reload(true);
            window.location.href = "/sellers-products";
        } else {
            alert(response.data.error);
        }
    }

    const getProducts = async () => {
        let data = {
            'seller_id': location.state,
            'action': "read"
        }
        let response = await common.apiRequest('post', '/seller/get-products', data);
        if (response.data.code == 'success') {
            setAllProducts(response.data.data);
        } else {
            setAllProducts([]);
        }
    }
    
    return (
        <>
        <h1>All Products</h1><br/>
        <Table striped>
            <thead>
                <tr className='table-primary'>
                    <th>Product Name</th>
                    <th>Product Description</th>
                    <th>Sub Products</th>
                    <th>Approve Products status</th>
                </tr>
            </thead>
            <tbody>
                {
                    allProducts.length == 0
                    ?
                        <tr>
                            <td align="center" colspan="2">
                                <center>No Products Available</center>
                            </td>
                        </tr>
                    :
                    allProducts.map((productData) => {
                        return (
                            <>
                                <tr>
                                    <td>{productData.name}</td>
                                    <td>{productData.description}</td>
                                    <td><button className='btn btn-primary btn-sm' onClick={() => showSubProducts(productData.product_items)}>View</button></td>
                                    <td>{productData.product_items[0].active == 1 ? "Approved" :"Not approved"}</td>
                                </tr>
                            </>
                        );
                    })
                }
            </tbody>
        </Table>
        <Modal isOpen={modal} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle}>Sub Products</ModalHeader>
            <ModalBody>
                <table class="seller-table table-responsive">
                <thead>
                        <tr>
                            <th>Product ID</th>
                            <th>Product SKU</th>
                            <th>Product Image</th>
                            <th>Price</th>
                            <th>HSN Code</th>
                            <th>Item weight</th>
                            <th>Item weight after pack</th>
                            <th>after sale services</th>
                            <th>faq</th>
                            <th>gst percent</th>
                            <th>legal_disclaimer</th>
                            <th>qty_in_stock</th>
                            <th>supply_gst_no</th>
                            <th>supply_pincode</th>
                            <th>supply_state</th>
                            <th>taxable_value</th>
                            <th>warranty_or_gurantee</th>
                            <th>Approve Status</th>
                        </tr>
                    </thead>
                    <tbody className='table-body'>
                    {
                        subProducts.map((product) => {
                            return (
                                <tr>
                                    <td>{product.id}</td>
                                    <td>{product.product_sku}</td>
                                    <td>
                                    {
                                            product.product_image ? 
                                                product.product_image.split(',').map((image) => {
                                                    return (
                                                        <img src={envConfig.api_url + image} alt="image" style={{ height: '100px', width: '100px' }} />
                                                    );
                                                }) 
                                            : ""
                                        }

                                    </td>
                                    <td>{product.price}</td>
                                    <td>{product.hsn_code}</td>
                                    <td>{product.item_weight}</td>
                                    <td>{product.after_pack_item_weight}</td>
                                    <td>{product.after_sale_services}</td>
                                    <td>{product.faq}</td>
                                    <td>{product.gst_percent}</td>
                                    <td>{product.legal_disclaimer}</td>
                                    <td>{product.qty_in_stock}</td>
                                    <td>{product.supply_gst_no}</td>
                                    <td>{product.supply_state}</td>
                                    <td>{product.supply_pincode}</td>
                                    <td>{product.taxable_value}</td>
                                    <td>{product.warranty_or_gurantee}</td>
                                    <td>
                                        {
                                            product.active == 1 ? "Approved" : <button className='btn btn-primary' onClick={() => approveProduct(product.id)}>Approve</button>  
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
        </table>
            </ModalBody>
        </Modal>
        </>
    )
}

export default SellersProducts;