import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const SystemConfig = () => {
    let navigate = useNavigate();
    const [allConfig, setAllConfig] = useState([]);
    const [editConfig, setEditConfig] = useState({key: "", value: "", id: ""});
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

	useEffect(() => {
        checkAccess();
	}, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            crudSystemConfig(0, 'read');
        }
    }

    const addNewConfig = () => {
        toggle();
    }

    const crudSystemConfig = async (id, action) => {
        let data = {
            'id': id,
            'action': action
        }
        if (action == 'create' || action == 'update') {
            data.key = $('#key').val().replace(/(\r\n|\n|\r)/gm, "");
            data.value = $('#value').val().replaceAll(/(\r\n|\n|\r)/gm, "");
        }
        let response = await common.apiRequest('post', '/admin/crud-system-config', data);
        if (response.data.code == 'success') {
            if (action == "read") {
                setAllConfig(response.data.data);
            } else {
                alert(response.data.message);
                if (action != "delete") {
                    toggle();
                }
                crudSystemConfig(0, 'read');
            }
        } else {
            alert(response.data.error);
        }
    }

    const editSystemConfig = (config) => {
        setEditConfig({
            key: config.key,
            value: JSON.stringify(config.value, null, 2),
            id: config._id
        });
        toggle();
    }

    return (
        <>
        <h1>All Configs</h1><br/>
        <button className='btn btn-primary btn-sm float-right mr-3 mb-5' onClick={addNewConfig}>Add New Config</button>
        <Table striped>
            <thead>
                <tr className='table-primary'>
                    <th>Key</th>
                    <th>Value</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    allConfig.length == 0
                    ?
                        <tr>
                            <td align="center" colspan="3">
                                <center>No Config Available</center>
                            </td>
                        </tr>
                    :
                    allConfig.map((config) => {
                        return (
                            <>
                                <tr>
                                    <td>{config.key}</td>
                                    <td>{JSON.stringify(config.value)}</td>
                                    <td>
                                        <button className='btn btn-warning btn-sm' onClick={() => editSystemConfig(config)}>Edit</button>
                                        <button className='btn btn-danger btn-sm' onClick={() => crudSystemConfig(config._id, 'delete')}>Delete</button>
                                    </td>
                                </tr>
                            </>
                        );
                    })
                }
            </tbody>
        </Table>
        <Modal isOpen={modal} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle}>Add Config</ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Key</label>
                    </div>
                    <div className='col-md-10'>
                        <input type='text' className='form-control' id="key" defaultValue={editConfig.key}/>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-2'>
                        <label>Value</label>
                    </div>
                    <div className='col-md-10'>
                        <textarea id="value" className='form-control' defaultValue={editConfig.value} rows="20"></textarea>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-md-2'></div>
                    <div className='col-md-10'>
                        <button className='btn btn-primary btn-sm' onClick={() => crudSystemConfig(editConfig.id ? editConfig.id: 0, editConfig.id ? 'update' : 'create')}>Submit</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
        </>
    )
}

export default SystemConfig;