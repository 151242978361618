// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Golos+Text&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.firstinput,.secondinput{
  align-items: center;
  border-color: black;
  border-radius: 5%;
  justify-content: center;
  margin-top: 5%;
  margin-left:40%;
  margin-right:5px
}
.columnitem{
  /* align-items: center;
  text-align: center; */
  /* margin-top: 5%; */
  margin-left:40%
}
.column,.subcolumn{
  font-size: 25px;
  font-family: 'Golos Text', sans-serif;
}
.mainhead{
  text-align: center;
  margin-top: 10px;
}
.firstinputbutton, .secondinputbutton{
  border-radius: 5%;
  border-color: transparent;
  background-color: blanchedalmond;
}
.AddButton{
  border-radius: 5%;
  border-color: transparent;
  background-color: rgb(213, 205, 255);
  margin-left: 50%;
  margin-top: 5%;
}`, "",{"version":3,"sources":["webpack://./src/component/catogory.css"],"names":[],"mappings":"AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,uBAAuB;EACvB,cAAc;EACd,eAAe;EACf;AACF;AACA;EACE;uBACqB;EACrB,oBAAoB;EACpB;AACF;AACA;EACE,eAAe;EACf,qCAAqC;AACvC;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,gCAAgC;AAClC;AACA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,oCAAoC;EACpC,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Golos+Text&display=swap');\n\n.firstinput,.secondinput{\n  align-items: center;\n  border-color: black;\n  border-radius: 5%;\n  justify-content: center;\n  margin-top: 5%;\n  margin-left:40%;\n  margin-right:5px\n}\n.columnitem{\n  /* align-items: center;\n  text-align: center; */\n  /* margin-top: 5%; */\n  margin-left:40%\n}\n.column,.subcolumn{\n  font-size: 25px;\n  font-family: 'Golos Text', sans-serif;\n}\n.mainhead{\n  text-align: center;\n  margin-top: 10px;\n}\n.firstinputbutton, .secondinputbutton{\n  border-radius: 5%;\n  border-color: transparent;\n  background-color: blanchedalmond;\n}\n.AddButton{\n  border-radius: 5%;\n  border-color: transparent;\n  background-color: rgb(213, 205, 255);\n  margin-left: 50%;\n  margin-top: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
