import React, { useEffect,useState } from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Table, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import $ from 'jquery';
import { useCSVDownloader } from 'react-papaparse';
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const SellerPayments = () => {
    let navigate = useNavigate();
    const { CSVDownloader } = useCSVDownloader();

    const [sellerPayments, setSellerPayments] = useState([]);
    const [paymentList, setPaymentList] = useState([]);
    const [approveButton, setApproveButton] = useState(false);
    const [modal, setModal] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);
    const [vendorPayment, setVendorPayment] = useState(0);

    useEffect(()=>{
        checkAccess();
    },[])

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        }
    }

	const fetchSellerPayments = async () => {
        if ($('#payment_for').val() == "vendor") {
            setVendorPayment(1);
        } else {
            setVendorPayment(0);
        }
		const data = {
            start_date: $('#start_date').val(),
            end_date: $('#end_date').val(),
            payment_for: $('#payment_for').val(),
            fetch_approved: $('#fetch_approved').is(':checked')
        }
		let response = await common.apiRequest('post', '/admin/fetch-seller-payments', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                setSellerPayments(response.data.data);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
	}

    const addToPaymentList = async (e, data) => {
        let list = paymentList;
        if (e.target.checked) {
            list.push(data);
        } else {
            list = list.filter(function( obj ) {
                return obj.seller_id !== data.seller_id;
            });
        }
        if (list.length > 0) {
            setApproveButton(true);
            $('#start_date').prop('disabled', true);
            $('#end_date').prop('disabled', true);
        } else {
            setApproveButton(false);
            $('#start_date').prop('disabled', false);
            $('#end_date').prop('disabled', false);
        }
        setPaymentList(list);
    }

    const approvePayment = async () => {
        let data = [];
        paymentList.map((payment) => {
            let tempData = {};
            tempData.seller_id = payment.seller_id;
            tempData.order_item_ids = vendorPayment ? payment.logistics_order_ids : payment.order_item_ids;
            tempData.order_payment_amount = payment.net_payment;
            tempData.payment_status = 'in_process';
            tempData.type = vendorPayment ? 'vendor' : 'supplier';
            data.push(tempData);
        });
        let finalData = {
            "action": "create",
            "data": data,
            "vendor_payment": vendorPayment
        }
        let response = await common.apiRequest('post', '/admin/crud-seller-payment', finalData);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                alert(response.data.message);
                setApproveButton(false);
                $('#start_date').prop('disabled', false);
                $('#end_date').prop('disabled', false);
                fetchSellerPayments();
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

    const exportData = () => {
        const columns = [
            {
                id: 'reg_id',
                displayName: 'Reg Id'
            }, 
            {
                id: 'fist_name',
                displayName: 'First Name'
            }
        ];
    }

    const toggle = () => {
        setModal(!modal);
        setOrderDetails([]);
    }

    const getOrderDetails = async (orderIds) => {
        let data = {
            'ids': orderIds
        }
        let response = await common.apiRequest('post', '/orders/get-order-by-order-id', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                setOrderDetails(response.data.data);
                setModal(true);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }
  
  return (
    <>
          
            <h1>Seller Payments</h1>
            <br />
            <div className='row'>
                <div className='col-md-3'>
                    <label>Start Date</label>
                    <input type='date' className='form-control' id="start_date"/><br/>
                    <input type='checkbox' id="fetch_approved"/> Fetch Approved
                </div>
                <div className='col-md-3'>
                    <label>End Date</label>
                    <input type='date' className='form-control' id="end_date"/>
                </div>
                <div className='col-md-3'>
                    <label>Payment For</label>
                    <select className='form-control' id="payment_for">
                        <option value="supplier">Supplier</option>
                        <option value="vendor">Vendor</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <br/>
                    <button type="button" className='btn btn-primary btn-sm mt-2' onClick={fetchSellerPayments}>Submit</button>
                </div>
            </div>
            <br/>
            <table class="orders-table table-responsive report-margin commontop table-container">
                <thead>
                    <tr className='table-primary'>
                        <th></th>
                        <th>Reg Id</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Total Order Amount</th>
                        <th>Total Taxable Amount</th>
                        <th>Total TCS</th>
                        <th>Net Payment</th>
                        <th>Bank Name</th>
                        <th>Bank Account No</th>
                        <th>IFSC Code</th>
                        <th>View Order Details</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sellerPayments.length > 0 ? 
                            sellerPayments.map((data) => {
                                return (
                                    <tr className='trtd'>
                                        <td>{
                                            !$('#fetch_approved').is(':checked') ? 
                                            <input type='checkbox' onClick={(e) => addToPaymentList(e, data)}/>
                                            : null
                                            }
                                        </td>
                                        <td>{data.reg_id}</td>
                                        <td>{data.first_name}</td>
                                        <td>{data.last_name}</td>
                                        <td>{data.total_order_amount}</td>
                                        <td>{data.total_taxable_amount}</td>
                                        <td>{data.total_tcs}</td>
                                        <td>{data.net_payment}</td>
                                        <td>{data.bank_name}</td>
                                        <td>{data.bank_account_no}</td>
                                        <td>{data.ifsc_code}</td>
                                        <td><button type="button" className='btn btn-primary btn-sm' onClick={() => getOrderDetails(data.order_item_ids)}>View Details</button></td>
                                    </tr>
                                )
                            })
                        :
                        "No Data"
                    }
                </tbody>
            </table>
            <br/>
            {
                approveButton ? 
                    <center><button type='button' className='btn btn-success btn-sm' onClick={approvePayment}>Approve</button></center>
                : ""
            }
            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Order Details</ModalHeader>
                <ModalBody>
                <table class="orders-table table-responsive report-margin commontop table-container">
                    <thead>
                        <tr className='table-primary'>
                            <th>Order Id</th>
                            <th>Invoice No</th>
                            <th>Order Type</th>
                            <th>Product Name</th>
                            <th>Order Price</th>
                            <th>Taxable Amount</th>
                            <th>Tax Percent</th>
                            <th>TAX</th>
                            <th>IGST Percent</th>
                            <th>IGST</th>
                            <th>CGST Percent</th>
                            <th>CGST</th>
                            <th>SGST Percent</th>
                            <th>SGST</th>
                            <th>UTGST Percent</th>
                            <th>UTGST</th>
                            <th>Marketing Charges</th>
                            <th>Discount</th>
                            <th>Other Charges</th>
                            <th>Shipment ID</th>
                            <th>Tracking No</th>
                            <th>TCS Rate</th>
                            <th>TCS</th>
                            <th>Bank Charges</th>
                            <th>Commission Charges</th>
                            <th>Net Payment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderDetails.map((data) => {
                                return (
                                    <tr className='trtd'>
                                        <td>{data.order.order_id}</td>
                                        <td>
                                            {data.order && data.order.invoices
                                            ? (data.order.invoices.find(invoice => invoice.invoice_type === 'seller_product') || { invoice_no: "" }).invoice_no : ""}
                                        </td>
                                        <td>{data.offline_sale_id ? 'Offline Sale' : data.service_id ? 'Service Sale' : 'Online Sale'}</td>
                                        <td>{data.product_item.product.name}</td>
                                        <td>{vendorPayment ? data.logistics_order.order_price : data.order_price}</td>
                                        <td>{vendorPayment ? data.logistics_order.taxable_value : data.taxable_amount}</td>
                                        <td>{vendorPayment ? data.logistics_order.gst_rate : data.tax_percent}</td>
                                        <td>{vendorPayment ? data.logistics_order.order_price - data.logistics_order.taxable_value : data.order_price - data.taxable_amount}</td>
                                        <td>{vendorPayment ? data.logistics_order.igst_percent : data.igst_percent}</td>
                                        <td>{vendorPayment ? data.logistics_order.igst : data.igst}</td>
                                        <td>{vendorPayment ? data.logistics_order.cgst_percent : data.cgst_percent}</td>
                                        <td>{vendorPayment ? data.logistics_order.cgst : data.cgst}</td>
                                        <td>{vendorPayment ? data.logistics_order.sgst_percent : data.sgst_percent}</td>
                                        <td>{vendorPayment ? data.logistics_order.sgst : data.sgst}</td>
                                        <td>{vendorPayment ? data.logistics_order.utgst_percent : data.utgst_percent}</td>
                                        <td>{vendorPayment ? data.logistics_order.utgst : data.utgst}</td>
                                        <td>{vendorPayment ? null : data.marketing_charges}</td>
                                        <td>{vendorPayment ? null : data.discount}</td>
                                        <td>{vendorPayment ? null : data.other_charges}</td>
                                        <td>{data.shipment_id}</td>
                                        <td>{data.tracking_no}</td>
                                        <td>{vendorPayment ? data.logistics_order.tcs_rate : data.tcs_rate}</td>
                                        <td>{vendorPayment ? data.logistics_order.tcs : data.tcs}</td>
                                        <td>{vendorPayment ? data.logistics_order.bank_charges : data.bank_charges}</td>
                                        <td>{vendorPayment ? data.logistics_order.commission_charges : data.commission_charges}</td>
                                        <td>{vendorPayment ? data.logistics_order.vendor_net_payment : data.supplier_net_payment}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                </ModalBody>
            </Modal>
        </>
  )
}

export default SellerPayments;