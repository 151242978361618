import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCSVDownloader } from 'react-papaparse';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';

const common = require('../utils/Common');

ModuleRegistry.registerModules([ClientSideRowModelModule]);

function SRRRReport() {
    const [reportData, setReportData] = useState([]);
    const [reportKeys, setReportKeys] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        checkAccess();
    }, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            getReportData();
        }
    }

    const { CSVDownloader } = useCSVDownloader();
    const csvData = reportData.map((dataItem) => {
      const csvRow = {};
      reportKeys.forEach((key) => {
        csvRow[key] = dataItem[key];
      });
      return csvRow;
    });

    const exportPDF = () => {
        const unit = "pt";
        const size = "A1"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 10;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        const title = "srrr report";
        const headers = reportKeys.map(key => {
            return key.charAt(0).toUpperCase() + key.slice(1);
        });
    
        const data = reportData.map(dataItem => {
            return reportKeys.map(key => dataItem[key]);
        });
    
        let content = {
            startY: 50,
            head: [headers],
            body: data
        };
    
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf");
    };

    const getReportData = async () => {
        var parameters = {
            'source':'Admin',
            'report_name' : 'srrr_reports'
        };
        let response = await common.apiRequest('post', '/reports/get-reports', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setReportKeys(data.keys);
        setReportData(data.reportData);
    }

    const columnDefs = useMemo(() => {
        if (reportKeys.length === 0) return [];

        return [
            { headerName: "Sl No.", valueGetter: "node.rowIndex + 1", width: 80 },
            ...reportKeys.map(key => ({
                field: key,
                headerName: key.replaceAll('_', ' '),
                headerClass: 'capitalize-header',
            }))
        ];
    }, [reportKeys]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true,
    }), []);

    return (
        <>      
            <div className="row report-margin">
                <div className='col-md-10'>
                    <h3>Srrr Reports</h3>
                </div>
                <CSVDownloader className="csvDownload"
                    filename={'Srrr report'}
                    data={() => csvData}
                >
                    Download Csv
                </CSVDownloader>
                <button className="btn csvDownload" onClick={exportPDF}>Download pdf</button>
            </div>
       
            <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={reportData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 25, 50]}
                />
            </div>
        </>
    )
}

export default SRRRReport;