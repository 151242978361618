import React, { useEffect, useState, useMemo } from 'react';
import './report.css';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useCSVDownloader } from 'react-papaparse';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { useNavigate } from 'react-router-dom';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../utils/Common');

export default function ReceiptReports() {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const { CSVDownloader } = useCSVDownloader();
  const navigate = useNavigate();

  useEffect(() => {
    checkAccess();
  }, []);

  const checkAccess = async () => {
    let haveAccess = await common.checkAccessRights(window.location.pathname);
    if (!haveAccess) {
      alert("Access Denied");
      navigate("/");
    } else {
      getReportData();
    }
}
  const getReportData = async () => {
    var parameters = {
      'source':'Admin',
      'report_name': 'receipt_reports'
    };
    let response = await common.apiRequest('post', '/reports/get-reports', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];

    // Set column definitions
    const cols = [
      {
        field: 'slNo',
        headerName: 'Sl No.',
        valueGetter: (params) => params.node.rowIndex + 1,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 100
      },
      ...data.keys.map(key => ({
        field: key,
        headerName: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
        filter: 'agTextColumnFilter',
        editable: true
      }))
    ];
    setColumnDefs(cols);

    // Set row data
    setRowData(data.reportData);
  }

  const exportPDF = () => {
    const doc = new jsPDF('landscape', 'pt', 'a4');
    doc.text("Receipt Report", 40, 40);
    
    const columns = columnDefs.map(col => ({ header: col.headerName, dataKey: col.field }));
    
    doc.autoTable({
      columns: columns,
      body: rowData,
      startY: 50,
    });

    doc.save("receipt_report.pdf");
  };

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    editable: true,
  }), []);

  return (
    <>
      <div className="row report-margin">
        <div className='col-md-10'>
        <h3>Receipt Reports</h3>
        </div>
        <div className="col-md-2 button-container">
          <CSVDownloader className="btn csvDownload"
            filename={'Receipt report'}
            data={rowData}
          >
            Download CSV
          </CSVDownloader>
          <button className="btn csvDownload" onClick={exportPDF}>Download PDF</button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className="ag-theme-quartz" style={{height: 600, width: '100%'}}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={[10, 25, 50]}
            />
          </div>
        </div>
      </div>
    </>
  )
}