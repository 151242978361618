// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* .footer{
    position: relative;
    /* bottom: 0; */
    /* width: 100%; */
    /* Set the fixed height of the footer here */
    /* height: 60px; */
    /* position: relative; */
    /* left: 0;
    bottom: 0; */
    .footer {
        background-color: #333; /* Change the background color as needed */
        color: #fff; /* Change the text color as needed */
        padding: 10px;
        position: relative;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .foterdata {
        text-align: center;
      }
      
      /* Optional: Add styles to the body to prevent content from being hidden behind the fixed footer */
      body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        padding-bottom: 40px; /* Adjust the value based on your footer height */
      }`, "",{"version":3,"sources":["webpack://./src/component/footer.css"],"names":[],"mappings":";AACA;;mBAEmB;IACf,iBAAiB;IACjB,4CAA4C;IAC5C,kBAAkB;IAClB,wBAAwB;IACxB;gBACY;IACZ;QACI,sBAAsB,EAAE,0CAA0C;QAClE,WAAW,EAAE,oCAAoC;QACjD,aAAa;QACb,kBAAkB;QAClB,SAAS;QACT,WAAW;QACX,aAAa;QACb,uBAAuB;QACvB,mBAAmB;MACrB;;MAEA;QACE,kBAAkB;MACpB;;MAEA,kGAAkG;MAClG;QACE,SAAS;QACT,UAAU;QACV,sBAAsB;QACtB,oBAAoB,EAAE,iDAAiD;MACzE","sourcesContent":["\n/* .footer{\n    position: relative;\n    /* bottom: 0; */\n    /* width: 100%; */\n    /* Set the fixed height of the footer here */\n    /* height: 60px; */\n    /* position: relative; */\n    /* left: 0;\n    bottom: 0; */\n    .footer {\n        background-color: #333; /* Change the background color as needed */\n        color: #fff; /* Change the text color as needed */\n        padding: 10px;\n        position: relative;\n        bottom: 0;\n        width: 100%;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n      }\n      \n      .foterdata {\n        text-align: center;\n      }\n      \n      /* Optional: Add styles to the body to prevent content from being hidden behind the fixed footer */\n      body {\n        margin: 0;\n        padding: 0;\n        box-sizing: border-box;\n        padding-bottom: 40px; /* Adjust the value based on your footer height */\n      }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
