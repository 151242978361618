// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feeback-component{
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.feedback-content ul li{
 margin: 20px 30px 20px 30px !important;
 font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/component/feedback.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;CACC,sCAAsC;CACtC,eAAe;AAChB","sourcesContent":[".feeback-component{\n    margin-top: 30px;\n    justify-content: center;\n    align-items: center;\n    display: flex;\n}\n\n.feedback-content ul li{\n margin: 20px 30px 20px 30px !important;\n font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
