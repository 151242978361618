import React, { useEffect, useState, useMemo } from 'react';
import './report.css';
import { useCSVDownloader } from 'react-papaparse';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form } from 'reactstrap';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../utils/Common');

export default function TdsReports() {
  const [rowData, setRowData] = useState([]);
  console.log(rowData);
  const [columnDefs, setColumnDefs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [bsrCode, setBsrCode] = useState('');
  const [challanDate, setChallanDate] = useState('');
  const [rateOfDeduction, setRateOfDeduction] = useState('');
  const [dateOfDeduction, setDateOfDeduction] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null); 

  const { CSVDownloader } = useCSVDownloader();
  const navigate = useNavigate();

  useEffect(() => {
    checkAccess();
  }, []);

  const checkAccess = async () => {
    let haveAccess = await common.checkAccessRights(window.location.pathname);
    if (!haveAccess) {
      alert("Access Denied");
      navigate("/");
    } else {
      dataTDS();
    }
  };

  const dataTDS = async () => {
    let data = { 'action': 'admin' };
    let response = await common.apiRequest('post', '/admin/crud-buyer-payment', data);
    if (response.status === 200) {
      if (response.data.code === 'success') {
        setRowData(response.data.data); 
      } else {
        alert(response.data.error);
      }
    } else {
      alert(response.data.error);
    }
  };

  useEffect(() => {
    const columns = [
      { headerName: "User ID", field: "user.reg_id" },
      { headerName: "PAN No", field: "user.pan_no" },  
      { headerName: "User Name as per PAN Card", valueGetter: params => `${params.data.user?.first_name || ''} ${params.data.user?.last_name || ''}` },
      { headerName: "Order ID", field: "order_id" },
      { headerName: "Taxable Amount", field: "taxable_value" },
      { headerName: "Commission Rate", field: "commission" },
      { headerName: "Commission Amount", valueGetter: params => (parseFloat(params.data.commission) / 100 * parseFloat(params.data.taxable_value)).toFixed(2) },  // Calculated field
      { headerName: "Date of Payment", field: "created_at" },
      { headerName: "Amount Of TDS", field: "tds" },
      { headerName: "Date Of Deduction", field: "date_of_deduction" },
      { headerName: "Rate Of Deduction", field: "rate_of_deduction" },
      { headerName: "Section", field: "section" },
      { headerName: "Challan No", field: "challan_no" },
      { headerName: "Challan Date", field: "challan_date" },
      { headerName: "BSR Code", field: "bsr_code" },
      { 
        headerName: "Actions", 
        cellRenderer: (params) => (
          <button className="btn btn-primary" onClick={() => openModal(params.data)}>
            Edit
          </button>
        ),
        filter: false,
        sortable: false
      }
    ];

    setColumnDefs(columns);
  }, []);

  const openModal = (data) => {
    setCurrentRow(data);
    setSelectedUserId(data.id); 
    setBsrCode(data.bsr_code || ''); 
    setChallanDate(data.challan_date || ''); 
    setRateOfDeduction(data.rate_of_deduction || ''); 
    setDateOfDeduction(data.date_of_deduction || ''); 
    setIsModalOpen(true);
  };
  

  const handleUpdate = async () => {
    const updatedData = {
      id: selectedUserId,  
      bsr_code: bsrCode,
      challan_date: challanDate,
      rate_of_deduction: rateOfDeduction,
      date_of_deduction: dateOfDeduction,
      'action': 'adminupdate'
    };
    console.log(updatedData);
    
    let response = await common.apiRequest('post', '/admin/crud-buyer-payment', updatedData);
    if (response.status === 200 && response.data.code === 'success') {
      const updatedRowData = rowData.map(row => row.id === updatedData.id ? updatedData : row);
      setRowData(updatedRowData);
      setIsModalOpen(false);
    } else {
      alert(response.data.error || 'Failed to update the data');
    }
  };

  const exportPDF = () => {
    const doc = new jsPDF('landscape', 'pt', 'a4');
    doc.text("TDS Report", 40, 40);

    const columns = columnDefs.map(col => ({ header: col.headerName, dataKey: col.field }));

    doc.autoTable({
      columns: columns,
      body: rowData,
      startY: 50,
    });

    doc.save("tds_report.pdf");
  };

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    editable: true,
  }), []);

  return (
    <>
      <div className="row report-margin">
        <div className='col-md-10'>
          <h3>TDS Reports</h3>
        </div>
        <div className="col-md-2 button-container">
          <CSVDownloader className="btn csvDownload"
            filename={'Tds report'}
            data={rowData}
          >
            Download CSV
          </CSVDownloader>
          <button className="btn csvDownload" onClick={exportPDF}>Download PDF</button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              pagination={true}
              paginationPageSize={10}
            />
          </div>
        </div>
      </div>
<Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
  <div className="modal-header">
    <h5 className="modal-title">Edit Details</h5>
    <button type="button" className="close" onClick={() => setIsModalOpen(false)}>
      <span>&times;</span>
    </button>
  </div>
  <div className="modal-body">
    <Form>
      <div className="form-group">
        <label>BSR Code</label>
        <input 
          type="text" 
          className="form-control" 
          value={bsrCode} 
          onChange={(e) => setBsrCode(e.target.value)} 
          placeholder="Enter BSR Code"
        />
      </div>
      <div className="form-group">
        <label>Challan Date</label>
        <input 
          type="date" 
          className="form-control" 
          value={challanDate} 
          onChange={(e) => setChallanDate(e.target.value)} 
          placeholder="Select Challan Date"
        />
      </div>
      <div className="form-group">
        <label>Rate of Deduction</label>
        <input 
          type="text" 
          className="form-control" 
          value={rateOfDeduction} 
          onChange={(e) => setRateOfDeduction(e.target.value)} 
          placeholder="Enter Rate of Deduction"
        />
      </div>
      <div className="form-group">
        <label>Date of Deduction</label>
        <input 
          type="date" 
          className="form-control" 
          value={dateOfDeduction} 
          onChange={(e) => setDateOfDeduction(e.target.value)} 
          placeholder="Select Date of Deduction"
        />
      </div>
    </Form>
  </div>
  <div className="modal-footer">
    <Button color="primary" onClick={handleUpdate}>Submit</Button>
    <Button color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
  </div>
</Modal>

    </>
  );
}





