import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DiscountApply from './Discount';
import './product.css'
const common = require('../utils/Common')
const ProductList = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([
    { id: 1, name: 'Product 1', price: 100 },
    { id: 2, name: 'Product 2', price: 150 },
    { id: 3, name: 'Product 3', price: 200 },
    // Add more products if needed
  ]);

  useEffect(() => {
    checkAccess();
  },[]);

  const checkAccess = async () => {
    let haveAccess = await common.checkAccessRights(window.location.pathname);
    if (!haveAccess) {
      alert("Access Denied");
      navigate("/");
    }
  }

  const updatePriceWithDiscount = (productId, discountedPercentage) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId
          ? { ...product, price: product.price * discountedPercentage }
          : product
      )
    );
  };

  return (
    <div className="product-list-container">
      <h2>Product List</h2>
      {products.map((product) => (
        <div className="product-item" key={product.id}>
          <p className="product-name">{product.name}</p>
          <p className="product-price">${product.price.toFixed(2)}</p>
          <DiscountApply
            productId={product.id}
            updatePrice={updatePriceWithDiscount}
          />
          {product.discounted && (
            <p className="discounted-price">
              Discounted Price: ${product.price.toFixed(2)}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductList;
