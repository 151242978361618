// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */
.product-list-container {
  max-width: 600px;
  margin: 0 auto;
}

.product-item {
  padding: 16px;
  border: 1px solid #ccc;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-name {
  font-size: 18px;
  font-weight: bold;
  flex-grow: 1;
}

.product-price {
  font-size: 16px;
  margin-right: 20px;
}

.discount-apply {
  display: flex;
  align-items: center;
}


.discounted-price {
  font-size: 16px;
  color: #888;
}
.dbutton{
  padding: 8px 16px;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.dinput{
  width: 70px;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.trtd td{
  color: black;
  border-width: thin;
}`, "",{"version":3,"sources":["webpack://./src/component/product.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,wCAAwC;AAC1C;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;;AAGA;EACE,eAAe;EACf,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":["/* styles.css */\n.product-list-container {\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n.product-item {\n  padding: 16px;\n  border: 1px solid #ccc;\n  margin-bottom: 12px;\n  display: flex;\n  align-items: center;\n  background-color: #f9f9f9;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.product-item:hover {\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.product-name {\n  font-size: 18px;\n  font-weight: bold;\n  flex-grow: 1;\n}\n\n.product-price {\n  font-size: 16px;\n  margin-right: 20px;\n}\n\n.discount-apply {\n  display: flex;\n  align-items: center;\n}\n\n\n.discounted-price {\n  font-size: 16px;\n  color: #888;\n}\n.dbutton{\n  padding: 8px 16px;\n  font-size: 14px;\n  background-color: #007bff;\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n}\n.dinput{\n  width: 70px;\n  padding: 8px;\n  font-size: 14px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  margin-right: 10px;\n}\n\n.trtd td{\n  color: black;\n  border-width: thin;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
