import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormGroup, Label, Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import $ from 'jquery';
import moment from 'moment';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const Buyers = () => {
    let navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [buyersData, setBuyersData] = useState([]);
    console.log(buyersData,"iiij");
    const [referralPayment, setReferralPayment] = useState(0);
    const [commissionOperationDate, setCommissionOperationDate] = useState({
        "from": null,
        "to": null
    });
    const [distribution, setDistribution] = useState([]);
    const [commissionAmount, setCommissionAmount] = useState(0);
    const [commissionAmountSeller, setCommissionAmountSeller] = useState(0);
    const [buyerId, setBuyerId] = useState(0);

	const getBuyers = async () => {
		let data = {
			"user_type" : 'buyer',
            'fromDate': $('#Date-from').val(),
            'toDate': $('#Date-to').val(),
		}
		let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                setBuyersData(response.data.data);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
	}

    const movePage = (path, data) => {
        navigate(path,{state: data });
    }

    const getBuyerCommissionConfig = async () => {
        let response = await common.getSystemConfig('buyer_commission_config');
		if (response) {
            let value = response['type'];
            setDistribution(response["distribution"]);
            if (value == 'monthly') {
                var date = new Date();
                var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                if (date == lastDay) {
                    setCommissionOperationDate({
                        'from': date.getFullYear() + '-' + (date.getMonth() < 10 ? '0'+date.getMonth() : date.getMonth()) + '-' + (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' 00:00:00',
                        'to': lastDay.getFullYear() + '-' + (lastDay.getMonth() < 10 ? '0'+lastDay.getMonth() : lastDay.getMonth()) + '-' + (lastDay.getDate() < 10 ? '0'+lastDay.getDate() : lastDay.getDate()) + ' 23:59:59',
                    });
                    setReferralPayment(1);
                }
            } else if (value == 'daily') {
                var date = new Date();
                setCommissionOperationDate({
                    'from': date.getFullYear() + '-' + (date.getMonth() < 10 ? '0'+date.getMonth() : date.getMonth()) + '-' + (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' 00:00:00',
                    'to': date.getFullYear() + '-' + (date.getMonth() < 10 ? '0'+date.getMonth() : date.getMonth()) + '-' + (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' 23:59:59',
                });
                setReferralPayment(1);
            }
        }
    }

    const calculateReferralPaymentAmount = async(userId, type) => {
        var data;
        if (type == 'buyer') {
            data = {
                'buyer_id': userId,
                'operation_date': commissionOperationDate,
                'distribution': distribution
            }
        } else if (type == 'seller'){
            data = {
                'seller_id': userId,
                'user_type': 'seller',
                'operation_date': commissionOperationDate
            }
        } else {
            alert('Invalid Operation');
            return;
        }
        let response = await common.apiRequest('post', '/seller/get-referral-payment-amount', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                if (response.data.data == 0) {
                    alert('Nothing to pay');
                    return;
                }
                if (type == 'buyer') {
                    setCommissionAmount(response.data.data);
                } else {
                    setCommissionAmountSeller(response.data.data);
                }
                setBuyerId(userId);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }
    const handleShowModal = (user) => {
        setSelectedUser(user);
        setShowModal(true);
    };
      const handleCloseModal = () => {
        setShowModal(false);
        setSelectedUser(null);
      };
    const makePayment = async (buyerId, type) => {
        let data = {
            'action': 'create',
            'buyer_id': buyerId,
            'payment_amount': type == 'buyer' ? commissionAmount : commissionAmountSeller,
            'type': type,
            'payment_status': 'done'
        }
        let response = await common.apiRequest('post', '/seller/crud-buyer-payments', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                alert(response.data.message);
                window.location.href = "/buyers";
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

	useEffect(() => {
        checkAccess();
	}, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            getBuyerCommissionConfig();
            getBuyers();
        }
    }
    const handleAccept =async (id) => {
        const data = {
            action: "update",
            id:id,
            pan_status: "1",
            "user_type" : 'buyer'
          };
          try {
            let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
            if (response.data.code === "success") {
                alert(response.data.message);
            } else {
              alert(response.data.error);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }    handleCloseModal();
        handleCloseModal();
      };
    
      const handleReject = async(id) => {
        const data = {
            action: "update",
            id:id,
            pan_status: "0",
            "user_type" : 'buyer'
          };
          try {
            let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
            if (response.data.code === "success") {
                alert(response.data.message);
            } else {
              alert(response.data.error);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }    handleCloseModal();
      };
      const handleActive = async (user) => {
        console.log(user, "User data");
        const data = {
            action: "updateActive",
            id: user.id,
            'active': user.active ? 0 : 1,
            "user_type": 'buyer'
        };
        
        try {
            let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
            if (response.data.code === "success") {
                alert(response.data.message);
                getBuyers()
            } else {
                alert(response.data.error);
            }
        } catch (error) {
            console.error('Error updating user status:', error);
        }
    };
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const handleEditShow = (data) => {
        console.log(data);
        setEditData(data);
        setEditModal(true);
    };
    const handleEditClose = () => {
        setEditModal(false);
        setEditData(null);
    };
    const addDetail = async (id) => {
        var parameters = {
            action: "updateNomini",
            "id": id,
            "user_type": 'buyer',
            pan_no: $('#pan_no').val(),
            first_name: $('#first_name').val(),
            last_name: $('#last_name').val(),
            email: $('#email').val(),
            mobile_phone: $('#mobile_phone').val(),
            bank_name: $('#bank_name').val(),
            bank_account_no: $('#bank_account_no').val(),
            ifsc_code: $('#ifsc_code').val(),
        };
        console.log(parameters,"kooooooo");
        let response = await common.apiRequest('post', '/admin/get-sellers-users-data', parameters);
        if (response.status == 200) {
          if (response.data.code == "success") {
            alert(response.data.message);
          } else {
            alert(response.data.error, 'error');
          }
        }
      }
      const [columnDefs] = useState([
        { field: 'reg_id', headerName: 'Reg ID', filter: 'agTextColumnFilter' },
        { field: 'pan_no', headerName: 'PAN No', filter: 'agTextColumnFilter' },
        { field: 'first_name', headerName: 'First Name', filter: 'agTextColumnFilter' },
        { field: 'last_name', headerName: 'Last Name', filter: 'agTextColumnFilter' },
        { field: 'email', headerName: 'Email', filter: 'agTextColumnFilter' },
        { field: 'mobile_phone', headerName: 'Mobile Phone', filter: 'agTextColumnFilter' },
        { field: 'totalPurchases', headerName: 'Total Purchases', filter: 'agTextColumnFilter' },
        { field: 'totalSales', headerName: 'Total Sales', filter: 'agTextColumnFilter' },
        { field: 'referrerBY', headerName: 'Referrer BY', filter: 'agTextColumnFilter' },
        { field: 'referredUserNames', headerName: 'Referred User Names', filter: 'agTextColumnFilter' },
        { field: 'commesion', headerName: 'Commesion', filter: 'agTextColumnFilter' },
        {
            headerName: 'Orders',
            cellRenderer: params => (
                <Button color="primary" size="sm" onClick={() => movePage('/buyer-orders', params.data.id)}>
                    All Orders
                </Button>
            )
        },
        {
            headerName: 'Pan image',
            cellRenderer: params => (
                <Button color="primary" onClick={() => handleShowModal(params.data)}>
                    View Image
                </Button>
            )
        },
        { field: 'active', headerName: 'Active Status', valueGetter: params => params.data.active ? "Active" : "Not Active" },
        {
            headerName: 'Active/Inactive',
            cellRenderer: params => (
                <Button onClick={() => handleActive(params.data)} className='submit'>
                    {params.data.active ? "Deactivate" : "Activate"}
                </Button>
            )
        },
        {
            headerName: 'Nomination',
            cellRenderer: params => (
                <Button onClick={() => handleEditShow(params.data)} className='submit'>Change Nomination details</Button>
            )
        }
    ]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
    }), []);

    return (
        <>
        <h1>All Buyers</h1><br/>
        <div className="col-md-12">
                        <Form className='row'>
                            <FormGroup className='col-md-4 row'>
                                <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                                <Input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getBuyers} className="form-control col-md-5" id="Date-from" name="Date-from"></Input>
                            </FormGroup>
                            <FormGroup className='col-md-4 row'>
                                <Label className="col-md-2 col-form-label" for="Date-to">To:</Label>
                                <Input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getBuyers} className="form-control col-md-5" id="Date-to" name="Date-to"></Input>
                            </FormGroup>
                        </Form>
                    </div>
        <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={buyersData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                />
            </div>
            <Modal isOpen={showModal} toggle={handleCloseModal}>
    <ModalHeader toggle={handleCloseModal}>User PAN Image</ModalHeader>
    <ModalBody>
        {selectedUser && selectedUser.user_pan_image && (
            <img
                src={envConfig.api_url + selectedUser.user_pan_image}
                style={{ width: '100%', height: 'auto' }}
                alt="User PAN"
            />
        )}
    </ModalBody>
    <ModalFooter>
        <Button color="success" onClick={() => handleAccept(selectedUser.id)}>
            Accept
        </Button>
        <Button color="danger" onClick={() => handleReject(selectedUser.id)}>
            Reject
        </Button>
    </ModalFooter>
</Modal>


      <Modal isOpen={editModal} toggle={handleEditClose}>
                <ModalHeader toggle={handleEditClose}>
                    Select here to change
                </ModalHeader>
                <ModalBody>
                <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>PAN No</Form.Label>
                                <Form.Control type="text" name="pan_no" defaultValue={editData?.pan_no || ''} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" name="first_name" id='first_name' defaultValue={editData?.first_name || ''} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" name="last_name" id='last_name' defaultValue={editData?.last_name || ''} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" id='email' defaultValue={editData?.email || ''} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Mobile Phone</Form.Label>
                                <Form.Control type="tel" name="mobile_phone" id='mobile_phone' defaultValue={editData?.mobile_phone || ''} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Bank Name</Form.Label>
                                <Form.Control type="text" name="bank_name" id='bank_name' defaultValue={editData?.bank_name || ''} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Bank Account No</Form.Label>
                                <Form.Control type="text" name="bank_account_no"    id='bank_account_no' defaultValue={editData?.bank_account_no || ''} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Bank IFSC</Form.Label>
                                <Form.Control type="text" name="ifsc_code" id='ifsc_code' defaultValue={editData?.ifsc_code || ''} />
                            </Form.Group>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => addDetail(editData.id)}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default Buyers;