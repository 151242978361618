import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import { useNavigate, useParams } from 'react-router-dom';
const common = require('../utils/Common');

const AdminUserTypeRights = () => {
    const attributes = useParams();
    let navigate = useNavigate();
    const [userTypeRights, setUserTypeRights] = useState([]);

    const crudAdminUserTypeRights = async (action, rights) => {
        let data = {
            "action": action,
            "user_type_id": attributes.user_type_id
        };
        if (rights) {
            data.admin_portal_id = rights.id;
            data.can_create = $('#can_create_'+rights.id).prop('checked');
            data.can_view = $('#can_view_'+rights.id).prop('checked');
            data.can_edit = $('#can_edit_'+rights.id).prop('checked');
            data.can_delete = $('#can_delete_'+rights.id).prop('checked');
        }
		let response = await common.apiRequest('post', '/admin/crud-admin-user-type-rights', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                if (action != "read") {
                    alert(response.data.message);
                    crudAdminUserTypeRights('read', 0);
                    return;
                }
                setUserTypeRights(response.data.data)
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

	useEffect(() => {
		if (!localStorage.getItem('token')) {
			navigate('/login');
		}
        crudAdminUserTypeRights('read', 0);
	}, []);

    return (
        <>
        <h1>Modify Rights</h1><br/>
        {
            userTypeRights.map((rights) => {
                return (
                    <>
                        <h3>Portal Name - {rights.portal_name}</h3>
                        <h5>Portal URL - {rights.portal_url}</h5>
                        <br/>
                        <input type="checkbox" id={"can_create_"+rights.id} defaultChecked={rights.admin_user_type_rights[0] ? rights.admin_user_type_rights[0].can_create : false} defaultValue={rights.admin_user_type_rights[0] ? rights.admin_user_type_rights[0].can_create : false}/> Can Create
                        <input type="checkbox" id={"can_view_"+rights.id} defaultChecked={rights.admin_user_type_rights[0] ? rights.admin_user_type_rights[0].can_view : false} defaultValue={rights.admin_user_type_rights[0] ? rights.admin_user_type_rights[0].can_view : false}/> Can View
                        <input type="checkbox" id={"can_edit_"+rights.id} defaultChecked={rights.admin_user_type_rights[0] ? rights.admin_user_type_rights[0].can_edit : false} defaultValue={rights.admin_user_type_rights[0] ? rights.admin_user_type_rights[0].can_edit : false}/> Can Edit
                        <input type="checkbox" id={"can_delete_"+rights.id} defaultChecked={rights.admin_user_type_rights[0] ? rights.admin_user_type_rights[0].can_delete : false} defaultValue={rights.admin_user_type_rights[0] ? rights.admin_user_type_rights[0].can_delete : false}/> Can Delete
                        <br/>
                        <button className='btn btn-primary btn-sm' onClick={() => crudAdminUserTypeRights((rights.admin_user_type_rights[0] ? 'update' : 'create'), rights)}>Save</button>
                        <br/><br/>
                    </>
                )
            })
        }
        </>
    )
}

export default AdminUserTypeRights;