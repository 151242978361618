import React, { useEffect,useState } from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom';
import { Table, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import $ from 'jquery';
import { useCSVDownloader } from 'react-papaparse';
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const BuyerCommission = () => {
    let navigate = useNavigate();
    const { CSVDownloader } = useCSVDownloader();

    const [userCommissions, setUserCommissions] = useState([]);
    const [paymentList, setPaymentList] = useState([]);
    const [approveButton, setApproveButton] = useState(false);
    const [modal, setModal] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);
    const [commissionPercent, setCommissionPercent] = useState(0);
    const [orderDetailsType, setOrderDetailsType] = useState(null);

    useEffect(()=>{
        checkAccess();
    },[])

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        }
    }
    
	const fetchUserCommissions = async () => {
        const data = {
            start_date: $('#start_date').val(),
            end_date: $('#end_date').val(),
            fetch_approved: $('#fetch_approved').is(':checked')
        }
		let response = await common.apiRequest('post', '/admin/fetch-user-commission', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                setUserCommissions(response.data.data);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
	}

    const addToPaymentList = async (e, data) => {
        let list = paymentList;
        if (e.target.checked) {
            list.push(data);
        } else {
            list = list.filter(function( obj ) {
                return obj.user_id !== data.user_id;
            });
        }
        if (list.length > 0) {
            setApproveButton(true);
            $('#start_date').prop('disabled', true);
            $('#end_date').prop('disabled', true);
        } else {
            setApproveButton(false);
            $('#start_date').prop('disabled', false);
            $('#end_date').prop('disabled', false);
        }
        setPaymentList(list);
    }

    const approvePayment = async () => {
        let finalData = {
            "action": "create",
            "data": paymentList
        }
        let response = await common.apiRequest('post', '/admin/crud-buyer-payment', finalData);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                alert(response.data.message);
                setApproveButton(false);
                $('#start_date').prop('disabled', false);
                $('#end_date').prop('disabled', false);
                fetchUserCommissions();
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

    const exportData = () => {
        const columns = [
            {
                id: 'reg_id',
                displayName: 'Reg Id'
            }, 
            {
                id: 'fist_name',
                displayName: 'First Name'
            }
        ];
    }

    const toggle = () => {
        setModal(!modal);
        setOrderDetails([]);
        setCommissionPercent(0);
        setOrderDetailsType(null);
    }

    const getOrderDetails = async (orderIds, commissionPercent, type) => {
        let data = {
            'ids': orderIds
        }
        let response = await common.apiRequest('post', '/orders/get-order-by-order-id', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                setOrderDetails(response.data.data);
                setModal(true);
                setCommissionPercent(commissionPercent);
                setOrderDetailsType(type);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }
  
  return (
    <>
          
            <h1>Buyer Commissions</h1>
            <br />
            <div className='row'>
                <div className='col-md-3'>
                    <label>Start Date</label>
                    <input type='date' className='form-control' id="start_date"/><br/>
                    <input type='checkbox' id="fetch_approved"/> Fetch Approved
                </div>
                <div className='col-md-3'>
                    <label>End Date</label>
                    <input type='date' className='form-control' id="end_date"/>
                </div>
                <div className='col-md-3'>
                    <br/>
                    <button type="button" className='btn btn-primary btn-sm mt-2' onClick={fetchUserCommissions}>Submit</button>
                </div>
            </div>
            <br/>
            <table class="orders-table table-responsive report-margin commontop table-container">
                <thead>
                    <tr className='table-primary'>
                        <th></th>
                        <th>Reg Id</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Total Shopping</th>
                        <th>Total Taxable Shopping</th>
                        <th>Commission Percent</th>
                        <th>Referral User Id</th>
                        <th>Total Order Amount Shopping</th>
                        <th>Taxable Order Amount Shopping</th>
                        <th>Commission Shopping</th>
                        <th>TDS Shopping</th>
                        <th>Receiveable Shopping</th>
                        <th>Total Order Amount Retail</th>
                        <th>Taxable Order Amount Retail</th>
                        <th>Commission Retail</th>
                        <th>TDS Retail</th>
                        <th>Receiveable Retail</th>
                        <th>Bank Name</th>
                        <th>Bank Account No</th>
                        <th>IFSC Code</th>
                        <th>View Order Details</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        userCommissions.length > 0 ? 
                            userCommissions.map((data) => {
                                return (
                                    <tr className='trtd'>
                                        <td>{
                                            !$('#fetch_approved').is(':checked') ? 
                                            <input type='checkbox' onClick={(e) => addToPaymentList(e, data)}/>
                                            : null
                                            }
                                        </td>
                                        <td>{data.reg_id}</td>
                                        <td>{data.first_name}</td>
                                        <td>{data.last_name}</td>
                                        <td>{data.total_shopping}</td>
                                        <td>{data.total_taxable_shopping}</td>
                                        <td>{data.commission_percent}</td>
                                        <td>{data.ref_user_reg_id}</td>
                                        <td>{data.total_order_amount_shopping}</td>
                                        <td>{data.total_taxable_amount_shopping}</td>
                                        <td>{data.commission_shopping}</td>
                                        <td>{(data.commission_shopping * 0.10).toFixed(2)}</td>
                                        <td>{(data.commission_shopping - data.commission_shopping * 0.10).toFixed(2)}</td>
                                        <td>{data.total_order_amount_retail}</td>
                                        <td>{data.total_taxable_amount_retail}</td>
                                        <td>{data.commission_retail}</td>
                                        <td>{(data.commission_retail * 0.10).toFixed(2)}</td>
                                        <td>{(data.commission_retail - data.commission_retail * 0.10).toFixed(2)}</td>
                                        <td>{data.bank_name}</td>
                                        <td>{data.bank_account_no}</td>
                                        <td>{data.ifsc_code}</td>
                                        <td>
                                            {
                                                data.order_item_ids_shopping ? 
                                                <><button type="button" className='btn btn-primary btn-sm' onClick={() => getOrderDetails(data.order_item_ids_shopping, data.commission_percent, 'shopping')}>View Shopping Order Details</button></> : null
                                            }
                                            <br/><br/>
                                            {
                                                data.order_item_ids_retail ? 
                                                <><button type="button" className='btn btn-primary btn-sm' onClick={() => getOrderDetails(data.order_item_ids_retail, data.commission_percent, 'retail')}>View Retail Order Details</button></> : null
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        :
                        "No Data"
                    }
                </tbody>
            </table>
            <br/>
            {
                approveButton ? 
                    <center><button type='button' className='btn btn-success btn-sm' onClick={approvePayment}>Approve</button></center>
                : ""
            }
            
            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Order Details</ModalHeader>
                <ModalBody>
                <table class="orders-table table-responsive report-margin commontop table-container">
                    <thead>
                        <tr className='table-primary'>
                            <th>S.No</th>
                            <th>Order Id</th>
                            <th>Invoice No</th>
                            <th>Product Name</th>
                            <th>Online {orderDetailsType == "shopping" ? "Shopping" : "Retail"} Amount</th>
                            <th>Online {orderDetailsType == "shopping" ? "Shopping" : "Retail"} Taxable Amount</th>
                            <th>Offline {orderDetailsType == "shopping" ? "Shopping" : "Retail"} Amount</th>
                            <th>Offline {orderDetailsType == "shopping" ? "Shopping" : "Retail"} Taxable Amount</th>
                            <th>Commission</th>
                            <th>TDS</th>
                            <th>Net ReceiveAble</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderDetails.map((data, index) => {
                                return (
                                    <tr className='trtd'>
                                        <td>{++index}</td>
                                        <td>{data.order.order_id}</td>
                                        <td>
                                            {data.order && data.order.invoices
                                            ? (data.order.invoices.find(invoice => invoice.invoice_type === (orderDetailsType == "shopping" ? 'product' : 'seller_product')) || { invoice_no: "" }).invoice_no : ""}
                                        </td>
                                        <td>{data.product_item.product.name}</td>
                                        <td>{data.offline_sale_id ? 0 : data.order_price}</td>
                                        <td>{data.offline_sale_id ? 0 : data.taxable_amount}</td>
                                        <td>{data.offline_sale_id ? data.order_price : 0}</td>
                                        <td>{data.offline_sale_id ? data.taxable_amount : 0}</td>
                                        <td>{data.taxable_amount * commissionPercent/100}</td>
                                        <td>{(data.taxable_amount * commissionPercent/100 * 0.10).toFixed(2)}</td>
                                        <td>{(data.taxable_amount * commissionPercent/100 - data.taxable_amount * commissionPercent/100 * 0.10).toFixed(2)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                </ModalBody>
            </Modal>
        </>
  )
}

export default BuyerCommission;