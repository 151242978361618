import React,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import "./catogory.css";
import $ from 'jquery';
import { Card, CardBody, CardTitle, Button, Input, FormGroup, Label } from 'reactstrap';
const common = require('../utils/Common');

const DiscountApply = () => {
  const navigate = useNavigate();
  const [inputValues, setInputValues] = useState({});
  const [selectValue, setSelectValue] = useState('');
 
  const [categories, setCategories] = useState([]);
  useEffect(()=>{
    checkAccess();
  },[]);

  const checkAccess = async () => {
    let haveAccess = await common.checkAccessRights(window.location.pathname);
    if (!haveAccess) {
      alert("Access Denied");
      navigate("/");
    } else {
      getCategories(0);
    }
  }

  const getCategories = async (categoryId) => {
   
    var parameters = {
      'action': 'read',
      'parent_category_id': categoryId
    };
    let response = await common.apiRequest('post', '/admin/crud-product-category', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];
    setCategories(data);
  }
  const handleClick = async () => {
    
    let data = {
      "action": "create",
      "active":"0",
      category_id: document.getElementById('category_id').value,
      cupan: document.getElementById('cupon').value,
      create_at: document.getElementById('create_at').value,
      updated_at: document.getElementById('updated_at').value,
		}
		let response = await common.apiRequest('post', '/admin/crud-coupan', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
  };
  return (
    <>
    <div className='container'>
    <div className='col-md-12'>
        <h4 className='mainhead'>Apply discount</h4>
      </div>
     <Card>
    
    <CardBody>
      <CardTitle>Select and Enter Data</CardTitle>

      <FormGroup>
        <Label for="parent_category_id">Select category</Label>
        <select
          id="category_id"
          className="form-control"
        >
          <option value="0">Select an entry</option>
          {
            categories.map((data) => {
              return (
                <option key={data.id} value={data.id}>
                  {data.category_name}
                </option>
              )
            })
          }
        </select>
      </FormGroup>
      <FormGroup>
        <Label for="newEntry">Enter Discount</Label>
        <Input
        type="text"
        id="cupon"
      />
      </FormGroup>
      <FormGroup>
        <Label for="newEntry">Enter Start Date</Label>
        <Input
        type="text"
        id="create_at"
      />
      </FormGroup>
      <FormGroup>
        <Label for="newEntry">Enter Enter end date</Label>
        <Input
        type="text"
        id="updated_at"
      />
      </FormGroup>

      <Button onClick={handleClick}>Get Values</Button>
    </CardBody>
  </Card>
    </div>
     
   
    </>
  )
}

export default DiscountApply
