import React, { useEffect, useState } from 'react';
import { Table, Button } from 'reactstrap';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
const common = require('../utils/Common');

const SellersBuyersOrders = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [allOrders, setAllOrders] = useState([]);
    const [orderStatuses, setOrderStatuses] =useState([]);

	useEffect(() => {
        checkAccess();
	}, []);

    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            if (!location.state) {
                navigate('/');
            }
            getAllOrders();
            setOrderStatuses(await common.getSystemConfig('product_service_order_status'));
        }
    }

    const getAllOrders = async () => {
        let data = {
            'seller_id' : location.state
        }
        let response = await common.apiRequest('post', '/seller/get-orders', data);
        if (response && response.data && response.data.code == 'success') {
            setAllOrders(response.data.data);
        } else {
            setAllOrders([]);
        }
    }

    const changeOrderStatus = async (orderId, orderStatus, orderPrice, userId) => {
        let data = {
            'action': 'update',
            'order_id': orderId,
            'order_status' : orderStatus,
            'order_price': orderPrice,
            'user_id': userId
        }
        let response = await common.apiRequest('post', '/orders/change-order-status-operations', data);
        if (response.data.code == 'success') {
            alert(response.data.message.replaceAll("_", " "));
            getAllOrders();
        } else {
            alert(response.data.error, 'error');
        }
    }

    return (
        <>
        <h1>All Orders</h1><br/>
            <table>
                <thead>
                    <tr className='table-primary'>
                        <th>Order ID</th>
                        <th>Order Type</th>
                        <th>Seller/Logistics/Both Order</th>
                        <th>Product Name</th>
                        <th>Product Description</th>
                        {/* <th>Product Image</th> */}
                        <th>Order Price</th>
                        <th>Order Quantity</th>
                        <th>Order Status</th>
                        <th>Order Completed On</th>
                        <th>Order Cancelled On</th>
                        <th>Payment ID</th>
                        <th>Payment Status</th>
                    </tr>
                </thead>
                <tbody className='table-body'>
                    {
                        allOrders.length == 0 ?
                        <tr>
                            <td align="center" colspan="12">
                                <center>No Orders Available</center>
                            </td>
                        </tr>
                        :
                        allOrders.map((order)=> {
                            return (
                            <tr>
                                <td>{order.order.order_id}</td>
                                <td>{order.product_item ? "Online Product Sale" : order.seller_service ? "Online Service Sale" : "Offline Product Sale"}</td>
                                <td>{(order.logistics_vendor_id == localStorage.getItem('userId') && order.seller_id == localStorage.getItem('userId')) || (order.offline_sale_product_datum && order.offline_sale_product_datum.offline_sale && order.offline_sale_product_datum.offline_sale.seller_id == localStorage.getItem("userId")) ? "Both" : order.logistics_vendor_id == localStorage.getItem('userId') ? "Logistics" : "Seller"} Order</td>
                                <td>{order.product_item ? order.product_item.product.name : order.seller_service ? order.seller_service.service_name : order.offline_sale_product_datum ? order.offline_sale_product_datum.product_description : "NA"}</td>
                                <td>{order.product_item ? order.product_item.product.description : order.selelr_service ? order.seller_service.service_description : order.offline_sale_product_datum ? order.offline_sale_product_datum.product_description : "NA"}</td>
                                {/* <td><img src={envConfig.api_url + (order.product_item ? order.product_item.product_image.split()[0] : order.seller_service.service_image)} style={{ 'height' : '200px', 'width' : '200px'}}/></td> */}
                                <td>{order.order_price}</td>
                                <td>{order.order_quantity}</td>
                                <td>
                                    <>
                                        <select id="order_status" value={order.order_status} className="form-control" onChange={(e) => changeOrderStatus(order.id, e.target.value, order.order_price, order.order.user_id)} style={{width: '130px'}}>
                                            {
                                                orderStatuses && orderStatuses.product && (order.product_item || order.offline_sale_product_datum) ? Object.entries(orderStatuses.product).map(([key, value]) => (
                                                    <option key={key} value={key}>{value}</option>
                                                ))
                                                :
                                                orderStatuses && orderStatuses.service && order.seller_service ? Object.entries(orderStatuses.service).map(([key, value]) => (
                                                    <option key={key} value={key}>{value}</option>
                                                )) : null
                                            }
                                        </select>
                                    </>
                                </td>
                                <td>{order.order_completed_on}</td>
                                <td>{order.order_cancelled_on}</td>
                                <td>{order.payment_id}</td>
                                <td>{order.payment_status}</td>
                            </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

export default SellersBuyersOrders;