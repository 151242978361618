import React from 'react'
import NavBar from './Nav'
import DataCards from './DataCards'
import ClientTable from './ClientTable'
import DataCharts from './DataCharts'
import UserPhotoCard from './UserPhotoCard'

const Home = () => {
  return (
    <>  
       
                <div className='main-Home'>
                    <div className=' col-12'>
                          <DataCards/>
                          <ClientTable/>
                          
                      </div>
                      <div className=' row  col-12'>
                        <div className='col-sm-12 col-md-6'>
                        <DataCharts/>
                        <ClientTable/>
                        </div>
                        <div className='col-sm-12 col-md-6'>
                        <UserPhotoCard/>
                        </div>
                          
                      </div>
                </div>   
    </>
  )
}

export default Home