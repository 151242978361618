import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import { useNavigate, NavLink } from 'react-router-dom';

const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const Sellers = () => {
    let navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [paymentOrders, setPaymentOrders] = useState([]);
    const [finalPaymentOrders, setFinalPaymentOrders] = useState([]);
    const [paymentForSeller, setPaymentForSeller] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const toggle = (orders = []) => {
        setModal(!modal);
        let finalPaymentOrders = [];
        orders.map((order) => {
            let orderItemIds = [];
            let paymentAmount = 0;
            let delivered = 0;
            order.order_items.map((data) => {
                if (data.order_status == 'delivered' && !data.seller_payment_status) {
                    if (paymentForSeller == 0) {
                        setPaymentForSeller(data.seller_id)
                    }
                    delivered = 1;
                    orderItemIds.push(data.id);
                    paymentAmount += parseInt(data.net_payment);
                }
            })
            if (delivered) {
                finalPaymentOrders.push({
                    orderId: order.id,
                    orderNo: order.order_id,
                    orderItemIds: orderItemIds.join(','),
                    paymentAmount: paymentAmount
                })
            }
        })
        setPaymentOrders(finalPaymentOrders);
        if (finalPaymentOrders.length == 0 && !modal) {
            alert('Nothing to pay');
            setModal(modal);
        }
    }
    const [sellersData, setSellersData] = useState([]);
	const getSellers = async () => {
		let data = {
			"user_type" : 'seller'
		}
		let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                setSellersData(response.data.data);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
	}

    const movePage = (path, sellerId) => {
        navigate(path,{state:sellerId});
    }

    const setTdsCommission = async (id, seller_id) => {
        let value = $('#'+id).val();
        let data = {
            'update': id,
            'value': value,
            'seller_id': seller_id
        }
        let response = await common.apiRequest('post', '/admin/update-seller-tds-commission', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                alert(response.data.message);
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

    const addPayment = async (order, e) => {
        let finalPayment = finalPaymentOrders;
        if (e.target.checked) {
            finalPayment.push(order);
        } else {
            finalPayment = finalPayment.filter(function( obj ) {
                return obj.orderId !== order.orderId;
            });
        }
        setFinalPaymentOrders(finalPayment);
    }

    const makePayment = async (sellerId) => {
        if (finalPaymentOrders == 0) {
            alert("Please select one order");
            return;
        }
        let paymentAmount = 0;
        let orderItemIds = "";
        finalPaymentOrders.map((payment) => {
            orderItemIds = (orderItemIds == "" ? payment.orderItemIds : (orderItemIds + "," + payment.orderItemIds));
            paymentAmount += parseInt(payment.paymentAmount);
        })
        let data = {
            'action': 'create',
            'seller_id': sellerId,
            'order_item_ids': orderItemIds,
            'order_payment_amount': paymentAmount,
            'payment_status': 'done'
        }
        let response = await common.apiRequest('post', '/seller/crud-seller-payments', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                alert(response.data.message);
                window.location.href = "/sellers";
			} else {
                alert(response.data.error);
            }
		} else {
            alert(response.data.error);
		}
    }

	useEffect(() => {
        checkAccess();
	}, []);
    const handleShowModal = (user) => {
        setSelectedUser(user);
        setShowModal(true);
      };
    
      const handleCloseModal = () => {
        setShowModal(false);
        setSelectedUser(null);
      };
    const checkAccess = async () => {
        let haveAccess = await common.checkAccessRights(window.location.pathname);
        if (!haveAccess) {
          alert("Access Denied");
          navigate("/");
        } else {
            if (!localStorage.getItem('token')) {
                navigate('/login');
            }
            getSellers();
        }
      }

      const handleAccept = async (id) => {
        const data = {
            action: "update",
            id: id,
            pan_status: "1",
            user_type: 'buyer'
        };
        try {
            let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
            if (response.data.code === "success") {
                alert(response.data.message);
                getSellers();
            } else {
                alert(response.data.error);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        }
        handleCloseModal();
    };
    
      const handleReject = async(id) => {
        const data = {
            action: "update",
            id:id,
            pan_status: "0",
            "user_type" : 'buyer'
          };
          try {
            let response = await common.apiRequest('post', '/admin/get-sellers-users-data', data);
            if (response.data.code === "success") {
                alert(response.data.message);
                getSellers();
            } else {
              alert(response.data.error);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }    handleCloseModal();
      };

    return (
        <>
        <h1>All Sellers</h1><br/>
        <Table striped>
            <thead>
                <tr className='table-primary'>
                    <th>Reg ID</th>
                    <th>PAN No</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Mobile Phone</th>
                    <th>TDS</th>
                    <th>Commission</th>
                    <th>Orders</th>
                    <th>Products</th>
                    <th>Pan image</th>
                </tr>
            </thead>
            <tbody>
                {
                    sellersData.length == 0
                    ?
                        <tr>
                            <td align="center" colspan="7">
                                <center>No Sellers Available</center>
                            </td>
                        </tr>
                    :
                    sellersData.map((sellerData) => {
                        return (
                            <>
                                <tr>
                                    <td>{sellerData.reg_id}</td>
                                    <td>{sellerData.pan_no}</td>
                                    <td>{sellerData.first_name}</td>
                                    <td>{sellerData.last_name}</td>
                                    <td>{sellerData.email}</td>
                                    <td>{sellerData.mobile_phone}</td>
                                    <td>
                                        <input type='text' id="tds" defaultValue={sellerData.seller_tds} placeholder='Enter TDS'/>
                                        <button className='btn btn-primary btn-sm' onClick={() => setTdsCommission('tds', sellerData.id)}>Save</button>
                                    </td>
                                    <td>
                                        <input type='text' id="commission" defaultValue={sellerData.seller_commission} placeholder='Enter Commission'/>
                                        <button className='btn btn-primary btn-sm' onClick={() => setTdsCommission('commission', sellerData.id)}>Save</button>
                                    </td>
                                    <td>
                                        <center>
                                            <Button color="primary" size="sm" onClick={() => movePage('/sellers-buyers-orders', sellerData.id)}>
                                                All Orders
                                            </Button>
                                        </center>
                                    </td>
                                    <td>
                                        <center>
                                            <Button color="primary" size="sm" onClick={() => movePage('/sellers-products', sellerData.id)}>
                                                All Products
                                            </Button>
                                        </center>
                                    </td>
                                    <td>
                                    <Button color="primary" onClick={() => handleShowModal(sellerData)}>
                                        View Image
                                    </Button>
                                    </td>
                                 
                                </tr>
                            </>
                        );
                    })
                }
            </tbody>
        </Table>

        <Modal isOpen={modal} toggle={() => toggle([])} size="lg">
            <ModalHeader toggle={() => {toggle([])}}>Make Payment</ModalHeader>
            <ModalBody>
                {
                    paymentOrders.map((order) => {
                        return (
                            <>
                            <input type='checkbox' onClick={(e) => addPayment(order, e)}/>&nbsp;&nbsp;
                            <label>{order.orderNo} (Amount Rs - {order.paymentAmount})</label>
                            <br/>
                            </>
                        )
                    })
                }
                <Button className="mt-3" color='primary' size='sm' onClick={() => makePayment(paymentForSeller)}>Pay</Button>
            </ModalBody>
        </Modal>
        <Modal isOpen={showModal} toggle={handleCloseModal}>
                <ModalHeader toggle={handleCloseModal}>User PAN Image</ModalHeader>
                <ModalBody>
                    {selectedUser && (
                        <img
                            src={envConfig.api_url + selectedUser.user_pan_image}
                            style={{ width: '100%', height: 'auto' }}
                            alt="User PAN"
                        />
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => handleAccept(selectedUser.id)}>
                        Accept
                    </Button>
                    <Button color="danger" onClick={() => handleReject(selectedUser.id)}>
                        Reject
                    </Button>
                </ModalFooter>
            </Modal>
        
        </>
        
    )
}

export default Sellers;